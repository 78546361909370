.GameBoard {
  //color: $red_dark;
  //background: $white;

  //background: $dots_bg;
  color: white;
  width: 100%;
  max-width: $container_width;
  margin: auto;
  padding: 2em;
  //@include border(horizontal,2px solid $yellow);
  min-height: 100vh;

  @include phone-only {
    max-width: none;
    padding: 4vmin;
  }
}
.GameBoard__logo {
  width: 50%;
  margin: 0 auto 1em;
  display: block;
}
.GameBoard__logout-btn {
  margin-left: 1em;
  font-size: 0.8em;
}
.GameBoard__top-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.GameBoard__game-code {
  font-family: "Courier New", Courier, monospace;
  font-variant: normal;
  text-transform: uppercase;
  //font-size: .9em;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-align: center;
  border: 3px solid rgba($yellow, 0.65);
  border-radius: 0.5em;
  padding: 0.4em 0.6em 0.2em;
  background: $red_dark;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  transition: background 0.15s $ease-in-out-quad, color 0.15s $ease-in-out-quad,
    border-color 0.15s $ease-in-out-quad;

  &.GameBoard__game-code--copied {
    color: white;
    border-color: white;
    background: white;
    font-size: 0;

    span:after {
      font-size: 0.95rem;
      display: block;
      content: "copied";
      color: $red;
      font-weight: bold;
      font-family: $body_font;
      padding: 0.43em 0.6em 0.25em;
    }
  }
}
.GameBoard__round-number {
  font-weight: $fw_black;
  font-size: 4em;
  opacity: 0.5;
  line-height: 1;
}
.GameBoard--round-started .GameBoard__round-number {
  opacity: 1;
}
.GameBoard__row {
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 1;

    &:nth-child(2) {
      @include not-phone {
        margin: 0 1em;
      }
      text-align: center;
      flex-grow: 1;
    }

    &.GameBoard__team-2 {
      &,
      .TeamStats__name {
        text-align: right;
      }

      .TeamStats__name {
        justify-content: flex-end;
      }

      .TeamStats__name div:first-child {
        order: 3;
      }

      .StatusDot {
        margin: 0 0 0 0.4em;
      }
    }
  }
}
.GameBoard--host .QuestionBoard__answer--revealed {
  cursor: pointer;

  &:hover {
    &,
    .QuestionBoard__answer-text {
      border-color: $yellow;
    }
  }
}
.GameBoard__questions-wrapper {
  width: 50%;
}
.GameBoard__row > .PlayerList {
  min-width: calc(25% - 1em);
}
.GameBoard--fast-money.GameBoard--host {
  .StrikeBoxes {
    display: none;
  }

  .GameBoard__questions-wrapper {
    width: 100%;
  }

  .GameBoard__row > .PlayerList {
    display: none;
  }
}
@include phone-only {
  .GameBoard--fast-money .GameBoard__round-number {
    font-size: 2em;
  }
}
.GameBoard__row--top {
  align-items: stretch;
}

.GameBoard__team-1,
.GameBoard__team-2 {
  width: 33vw;

  .TeamStats {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
