
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;700;800&display=swap');
@import "fontawesome-free-5.15.1-web/css/all.min.css";
@import "../../../../common/scss-lib/bourbon/addons/timing-functions";
@import "../../../../common/scss-lib/framework/variables";
@import "../../../../common/scss-lib/framework/mixins";
@import "../../../../common/scss-lib/framework/framework";

@import './variables';

body
{
    background: $dots_bg;
    font-family: $body_font;
    color: white;
    font-variant: small-caps;
    font-size: 16px;
    
    *
    {
        font-variant: inherit;
    }

}

.btn--blank
{
    @include no-button-style;
    box-shadow: none;
    padding: 0;
}

.placeholder
{
    font-size: .9em;
    border: 1px solid rgba(white,.25);
    padding: .5em;
    border-radius: 2px;
    display: block;
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

dl
{
  display: grid;
  grid-template-columns: .66fr 1.34fr;
  grid-template-rows: auto;

  dd
  {
    margin: 0;
  }

  dt
  {
    font-weight: bold;
  }

  dd,dt
  {
    padding-bottom: .5em;
  }
}

@import './forms';
@import './modal';
@import './components/Loading';
@import './components/Alert';
@import './components/TagSelect';
@import './components/PassOrPlay';
@import './components/HostControls';
@import './components/StrikeBoxes';
@import './components/BigStrikeBoxes';
@import './components/PlayerList';
@import './components/TeamPicker';
@import './components/SignIn';
@import './components/GameBoard';
@import './components/TeamStats';
@import './components/StatusDot';
@import './components/QuestionPicker';
@import './components/QuestionBoard';
@import './components/FastMoneyBoard';
@import './components/FastMoneyAnswers';
@import './components/Timer';
@import './components/Buzzer';
@import './components/MuteButton';
@import './components/AboutInfo';
@import './components/FieldSet';
@import './components/GameSettingsModal';
@import './components/StakesModal';
@import './components/Modal';
@import './components/FastMoneyInstructionsModal';