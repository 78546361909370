.GameSettingsModal .FieldSet:not(:last-child)
{
    margin-bottom: 1em;
}

.GameSettingsModal__team-settings
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    input
    {
        width: calc(50% - 1vmin);
    }
}

.GameSettingsModal__team-instructions
{
    width: 100%;
    margin-top: 0;
}

.GameSettingsModal .QuestionPicker__Question
{
    border: 2px solid darken($red_dark,10%);
    background: rgba($red,.5);
}

.GameSettingsModal__rr-stakes textarea
{
    width: 100%;
    height: 4em;
}
.GameSettingsModal__title
{
    margin: 0;
}
.GameSettingsModal .QuestionPicker .cta
{
    display: none;
}
.GameSettingsModal__description--modal-desc
{
    margin-bottom: 1.5em;
    margin-top: 0;
    font-size: 1.2em;
}
.FieldSet .GameSettingsModal__description
{
    margin-top: 0;
}
.GameSettingsModal__title 
{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cta
    {
        font-size: 1rem;
        position: sticky;
        display: block;
        top: 0;
    }
}
.GameSettingsModal__inner > .cta
{
    float: right;
}
.GameSettingsModal__description strong
{
    color: $yellow;
}
.GameSettingsModal__team-settings-controls
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input
    {
        margin-bottom: 0;
    }
}
.GameSettingsModal__generate-team,
.GameSettingsModal__generate-stakes
{
    @include no-button-style;
    background: transparent;
    padding: 0;
    color: $yellow;
    border: none;
    box-shadow: none;
    margin: 0 1em 0 .5em;
    cursor: pointer;

    &:last-child
    {
        margin-right: 0;
    }
}