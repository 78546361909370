input
{
    &[type="text"]
    {
        @include animated;
        outline: none;
        border-radius: 0;
        border-color: $red_dark;
        padding: .4em .8em .2em;
        line-height: 1;
        font-weight: $fw_medium;
        margin-bottom: .5em;

        &:focus
        {
            border-color: $yellow;
        }
    }

    &[disabled]
    {
        opacity: .5;
        border-color: lighten(black,50%);
    }
}

button, select
{
    @include no-button-style;
    @include animated;
    outline: none;
    font-weight: $fw_bold;
    //text-transform: uppercase;
    border: 2px solid transparent;  
    font-size: 1.1em;
    background: $white;
    color: $red_dark;
    box-shadow: 0 0 .25em 0 rgba(black,.5);
    padding: .25em .8em;
    line-height: 1;

    &.cta:not([disabled])
    {
        background: $red_dark;
        color: $yellow;
        border: 2px solid $yellow;

        &:focus
        {
            border-color: $yellow;
            box-shadow: none;
            color: white;
        }
    }

    @include not-phone
    {
        & + button
        {
            margin-left: 1em;
        }
    }

    &[disabled]
    {
        opacity: .6;
        color: black;
        cursor: not-allowed;
    }
}