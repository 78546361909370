.PassOrPlay
{
    font-size: 1rem;
}
.PassOrPlay__inner
{
    text-align: center;
    font-size: 1.5em;

    @media (min-width: $small_break)
    {
        width: 33vw;
    }

    @include phone-only
    {
        button
        {
            max-width: none;
            width: 100%;
            margin-bottom: .5em;
        }
    }
}
.GameBoard--host .PassOrPlay button
{
    cursor: pointer;
}

@include players-only
{
    .PassOrPlay button
    {
        display: none;
    }
    .PassOrPlay
    {
        background: transparent;
        position: relative;
        border: 0;
        line-height: 1;
        color: white;

        h3
        {
            display: none;
        }
    }

    .PassOrPlay__title
    {
        margin: 0;
        padding: 0;
    }
}
