.QuestionPicker__questions
{
    padding: 0;
    margin-bottom: 1em;
}
.QuestionPicker__questions,
.QuestionPicker__Question
{
    display: block;
    list-style: none;
}
.QuestionPicker__Question
{
    background: $red;
    color: $yellow;
    padding: .3em .8em;
    margin-bottom: .2em;
}
@include phone-only
{
    .QuestionPicker button
    {
        margin-bottom: .4em;
    }
}
.QuestionPicker__stakes
{
    margin-bottom: 1em;
}
.QuestionPickerModal__instructions
{
    margin-top: 1em;

    strong
    {
        color: $yellow;
    }
}
.QuestionPicker .TagSelect
{
    margin-left: .5em;
}