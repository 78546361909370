.QuestionBoard__answers
{
    $gap : .5em;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4,3.25em);
    gap: $gap / 2 $gap / 2;
    grid-auto-flow: column;
    padding: $gap;
    margin: 0;
    list-style: none;
    font-size: 1.5em;
}

.QuestionBoard__board-score,
.QuestionBoard__answers
{
    background: darken($red_dark,15%);
    box-shadow: $board_glow;
    border-radius: 1.5rem;
    border: 4px solid $yellow;
}

.QuestionBoard__board-score
{
    display: inline-block;
    font-size: 2.5em;
    font-weight: $fw_black;
    padding: .5em 1em 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: translateY(4px);
    border-bottom: none;
}

.QuestionBoard__answer
{
    $inner_rad : .5em;
    overflow: hidden;
    
    &:nth-child(1)
    {
        border-top-left-radius: $inner_rad;
    }
    &:nth-child(4)
    {
        border-bottom-left-radius: $inner_rad;
    }
    &:nth-child(5)
    {
        border-top-right-radius: $inner_rad;
    }
    &:nth-child(8)
    {
        border-bottom-right-radius: $inner_rad;
    }
    list-style: none;
    margin: 0;
    //padding: 1em .5em;
    background: $red;
    border: 2px solid lighten($red,25%);

    &:not(.QuestionBoard__answer--revealed)
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.QuestionBoard__answer-text
{
    text-transform: uppercase;
    justify-content: center;
    

    &,span
    {
        font-variant: normal;
    }

    font-size: .8em;
    display: block;
    padding-right : .2em;
    //margin-right: .2em;
    border-right-width: 2px;
    border-right-style: solid;
    flex-grow: 2;
    font-weight: $fw_medium;
}
.QuestionBoard__answer-points
{
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    flex-grow: 0;
    font-weight: $fw_bold;
    display: block;
    width: 3em;
    font-size: 1.1em;

    .QuestionBoard__answer-num
    {
        display: none;
    }
    
}
@include host-only
{
    .QuestionBoard__answer-text
    {
        position: relative;
        
        .QuestionBoard__answer-num
        {
            display: block;
            position: absolute;
            top: 3px;
            left: 3px;
            font-size: .75em;
            border: none;
            box-shadow: none;
            background: transparent;
        }
    }
}
.QuestionBoard__answer-text,
.QuestionBoard__answer-num
{
    border-color: $red_light;
}

.QuestionBoard__answer-num
{
    font-size: 1em;
    line-height: 1;
    padding: .25em;
    color: white;
    background: darken($red_dark,5%);
    border-width: 2px;
    border-style: solid;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    border-radius: 50%;
    font-weight: $fw_medium;
    display: inline-block;
    box-shadow: 0 0 .35em .01em black;
}

.QuestionBoard__answer,
.QuestionBoard__answer-points
{
    background: radial-gradient(circle, rgba(237,27,36,1) 0%, rgba(153,16,21,1) 100%);
}
.QuestionBoard__answer-details
{
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    align-items: center;
    height: 100%;
}
.QuestionBoard__question
{
    width: 65%;
    margin: 0 1em 0 0;

    .fa-arrow-right
    {
        margin-left: .5em;
        color: $yellow;
    }
}
.QuestionBoard .HostControls
{
    width: 35%;
}
.QuestionBoard__question-and-controls
{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 1em 0;
}
.QuestionBoard
{
    margin: 1em 0;

    @media (max-width: $small_break)
    {
        font-size: .75em;
    }

    @include phone-only
    {
        font-size: .6em;
    }
}
@include players-only
{
    .QuestionBoard__answer--revealed,
.QuestionBoard__answer--answered,
{
    .QuestionBoard__answer-num
    {
        display: none;
    }
}
}

.QuestionBoard__answer--revealed,
.QuestionBoard__answer--answered,
.GameBoard--host .QuestionBoard__answer:not(.QuestionBoard__answer--disabled)
{
    padding: 0;
    

    .QuestionBoard__answer-details
    {
        align-items: stretch;
        width: 100%;
    }

    .QuestionBoard__answer-text,
    .QuestionBoard__answer-points
    {
        display: flex;
        align-items: center;
        padding: 1rem;
    }
}
.QuestionBoard__answer--disabled,
.GameBoard--host .QuestionBoard__answer--answered,
.GameBoard--host .QuestionBoard__answer--revealed
{
    opacity: .35;
}
