.StakesModal__stakes
{
    @include not-phone
    {
        font-size: 2.5em;
    }

    @include phone-only
    {
        font-weight: bold;
        font-size: 1.25em;
    }
    
    line-height: 1.2;

    legend
    {
        @include not-phone
        {
            font-size: 1.5rem;
        }
    }

    margin-bottom: 1em;
}

.StakesModal__instructions
{
    @include not-phone
    {
        font-size: 1.25em;
    }
    
    strong
    {
        color: $yellow;
    }

    margin-bottom: 2em;
}
.StakesModal__title
{
    margin-bottom: .5rem;
}