.SignIn
{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $dots_bg;
}
.SignIn .Alert,
.SignIn__form
{
    width: 90%;
}
.SignIn__form,
.SignIn .Alert
{
    box-shadow: 0 0 .5em 0 rgba(black,.75);
}
.SignIn__form
{
    background: $white;
    padding: 1em;
    border: 2px solid $red_dark;
    display: grid;
    grid-template-columns: calc(60% - .5em) calc(40% - .5em);
    grid-template-rows: 2em 2em;
    gap: 1em;

    input[type="text"]
    {
        margin-right: 1em;
        width: 60%;
    }

    button:not(.TeamPicker__cta)
    {
        margin-bottom: 1em;
        flex-grow: 1;
        //padding: .2em .8em .1em;
    }

    input[type="text"],button:not(.TeamPicker__cta)
    {
        width: 100%;
        height: 100%;
    }

    button
    {
        @include animated($speed : .15s);
    }

    button[disabled]
    {
        opacity: 0;
    }
}

.SignIn__logo
{
    margin-bottom: 2em;
}

.SignIn__inner
{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    
    @include phone-only
    {
        width: 33vw;
    }
}
.SignIn .Alert
{
    margin: 1em 0 0;
}
.Alert__code-field
{
    text-transform: uppercase;

    @include style-placeholder
    {
        text-transform: none;
    }
}