@include phone-only
{
    .TeamStats
    {
        font-size: .5em;
    }
}
.TeamStats__name
{
    font-size: 2em;
    font-weight: $fw_black;
    display: flex;
    align-items: flex-end;
    line-height: 1;
    margin-bottom: 1rem;
    
}
.TeamStats__name-label
{
    word-break: break-word;
}
@include phone-only
    {
        .TeamStats__name-label
        {
            font-size: 1.5rem;
        }
    }
.TeamStats__score
{
    font-size: 3em;
    font-weight: $fw_bold;
    border: 3px solid $yellow;
    border-radius: .3em;
    text-align: center;
    background: $red_dark;
    padding: 0 .25em;
}
.TeamStats__score-field
{
    width: 2em;
    text-align: center;
    background: transparent;
    color: white;
    border: none;
    font-weight: $fw_black;
    padding: .15em 0 .15em 12px;
}
.TeamStats__score--editing
{
    background: darken($red,25%);

    &, .fas, .TeamStats__score-field
    {
        color: $yellow;
    }
}

.TeamStats__score-field,
.TeamStats__score-wrap
{
    min-width: 0;
    flex-grow: 1;
}
.TeamStats__score
{
    display: flex;
    align-items: center;
}
.TeamStats__btn
{
    @include no-button-style;
    box-shadow: none;
    padding: 0;
    color: white;
    font-size: 1rem;
}