.AboutInfo > .Modal__button
{
    position: fixed;
    top: 2vmin;
    right: 2vmin;
    @include no-button-style;
    box-shadow: none;
    color: white;
    cursor: pointer;
    font-size: .8em;

    &:hover
    {
        color: $yellow;
    }
}

.AboutInfo dialog:not([open])
{
    display: none;
}

.AboutInfo:not(.AboutInfo--show)
{
   // display: none;
}
.AboutInfo .Modal__inner
{
    font-variant: normal;
    overflow: auto;
    color: #333;
    background: $white;
    border: none;

    @include scrollbar-style($bar-color: $red_dark);
}
.AboutInfo__close-modal
{
    float: right;
}
.AboutInfo h1 {
    display: none;
}
.AboutInfo .Modal__close
{
    color: $red_dark;
}
.AboutInfo .Modal__header
{
    background: $white;
}