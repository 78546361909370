.modal
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(black,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 999;
    pointer-events: none;
}
.modal__inner
{
    pointer-events: all;
    max-width: calc(100% - 4em);
    max-height: calc(100% - 4em);
    background: $red_dark;
    border: 2px solid $yellow;
    padding: 2em;
    width: 80%;
    box-shadow: 0 0 5vw 0 black;
    overflow: auto;

    @include phone-only
    {
        width: 100%;
        max-width: none;
        max-height: 98%;   
    }

    &::-webkit-scrollbar {
        width: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $yellow;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    @include phone-only
    {
        button
        {
            max-width: calc(50% - .5em);
            margin: 0 .25em;
            padding: .25em;
        }
    }
}
