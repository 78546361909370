$red : #ed1b24;

$red_dark : darken($red,10%);

$red_light : lighten($red,30%);

$white : #f7f5e6;// #faeff0;

$black : #333;

$yellow : #fee60d;

$body_bg : $red;

$epilogue : 'Epilogue', Roboto, Arial, Helvetica, sans-serif;

$body_font : $epilogue;

$dots : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAL0lEQVQoU2NkIAAYSVLw//9/NZAGRkbGWzCNcBNgknAJqCLiFYB04rUCl2MJ+gIAXLQQCeHUB60AAAAASUVORK5CYII=);

$dots_bg : $red_dark $dots repeat top left;

$phone_break : 500px;

$small_break : 1000px;

$board_glow : 0 0 3em 0 rgba($yellow,.4);

@mixin phone-only
{
    @media (max-width: $phone_break)
    {
        @content;
    }
}

@mixin not-phone {
    @media (min-width: $phone_break + 1) {
        @content;
    }
}

@mixin small-only
{
    @media (max-width: $small_break)
    {
        @content;
    }
}
@mixin players-only
{
    .GameBoard:not(.GameBoard--host)
    {
        @content;
    }
}

@mixin host-only
{
    .GameBoard--host
    {
        @content;
    }
}