.PlayerList
{
    list-style-type: none;
    padding: 0;
    font-size: 1.75em;
    font-variant: normal;
    text-transform: none;
    font-weight: $fw_medium;
    padding-top: 2em;
    flex-shrink: 1;

    @include phone-only
    {
        display: none;
    }

    &:last-child
    {
        text-align: right;
    }
}
.PlayerList__player--buzzed
{
    color: $yellow;
    font-weight: $fw_bold;
}
    